.rc-tree {
  margin: 0;
  height: 100%;
  overflow: scroll;
  font-family: Inter;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}
.rc-tree-focused:not(.rc-tree-active-focused) {
  border-color: green;
}
.rc-tree .rc-tree-treenode {
  overflow-wrap: break-word;
  overflow: scroll;
  margin: 0;
  padding: 15px 5px;
  line-height: 20px;
  font-size: 20px;
  white-space: nowrap;
  list-style: none;
  outline: 0;
  border-bottom: 1px solid #e9e9e9;
}
.rc-tree .rc-tree-treenode .draggable {
  color: #333;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.rc-tree .rc-tree-treenode.drag-over > .draggable {
  color: white;
  background-color: #316ac5;
  border: 1px #316ac5 solid;
  opacity: 0.8;
}
.rc-tree .rc-tree-treenode.drag-over-gap-top > .draggable {
  border-top: 2px blue solid;
}
.rc-tree .rc-tree-treenode.drag-over-gap-bottom > .draggable {
  border-bottom: 2px blue solid;
}
.rc-tree .rc-tree-treenode.filter-node > .rc-tree-node-content-wrapper {
  color: #a60000 !important;
  font-weight: bold !important;
}
.rc-tree .rc-tree-treenode ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
  display: inline-block;
  height: 17px;
  margin: 0;
  padding: 1px 3px 0 0;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
}

.rc-tree .rc-tree-treenode span.rc-tree-checkbox {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 2px;
  line-height: 40px;
  vertical-align: middle;
  background-color: transparent;
  background-size: 100% 100%;
  background-image: url('/unchecked.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  outline: none;
  cursor: pointer;
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-icon__customize,
.rc-tree .rc-tree-treenode span.rc-tree-checkbox.rc-tree-icon__customize,
.rc-tree .rc-tree-treenode span.rc-tree-iconEle.rc-tree-icon__customize {
  background-image: none;
}
.rc-tree .rc-tree-treenode span.rc-tree-icon_loading {
  margin-right: 2px;
  vertical-align: top;
  background: url('data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7')
    no-repeat scroll 0 0 transparent;
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher-noop {
  cursor: auto;
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_open {
  background-position: -93px -56px;
}
.rc-tree .rc-tree-treenode span.rc-tree-switcher.rc-tree-switcher_close {
  background-position: -75px -56px;
}
.rc-tree .rc-tree-treenode span.rc-tree-checkbox {
  width: 13px;
  height: 13px;
  margin: 0 3px;
  background-position: 0 0;
}
.rc-tree .rc-tree-treenode span.rc-tree-checkbox-checked {
  background-image: url('/checked.png');
}
.rc-tree .rc-tree-treenode span.rc-tree-checkbox-indeterminate {
  background-image: url('/indeterminate.png');
}
.rc-tree .rc-tree-treenode span.rc-tree-checkbox-disabled {
  background-image: url('/disabled-checked.png');
}
.rc-tree
  .rc-tree-treenode
  span.rc-tree-checkbox.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
  background-image: url('https://e7.pngegg.com/pngimages/769/305/png-clipart-black-check-illustration-checkbox-computer-icons-check-mark-symbol-meet-miscellaneous-angle.png');
}
.rc-tree
  .rc-tree-treenode
  span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
  position: relative;
  background: #ccc;
  border-radius: 3px;
}
.rc-tree
  .rc-tree-treenode
  span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled::after {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 5px;
  height: 0;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  content: ' ';
}
.rc-tree:not(.rc-tree-show-line) .rc-tree-treenode .rc-tree-switcher-noop {
  background: none;
}
.rc-tree.rc-tree-show-line .rc-tree-treenode:not(:last-child) > ul {
  background: url('data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7')
    0 0 repeat-y;
}
.rc-tree.rc-tree-show-line
  .rc-tree-treenode:not(:last-child)
  > .rc-tree-switcher-noop {
  background-position: -56px -18px;
}
.rc-tree.rc-tree-show-line
  .rc-tree-treenode:last-child
  > .rc-tree-switcher-noop {
  background-position: -56px -36px;
}
.rc-tree-child-tree {
  display: none;
}
.rc-tree-child-tree-open {
  display: block;
}
.rc-tree-treenode-disabled > span:not(.rc-tree-switcher),
.rc-tree-treenode-disabled > a,
.rc-tree-treenode-disabled > a span {
  color: #a19f9f;
  cursor: not-allowed;
}
.rc-tree-treenode-active {
  background: rgba(0, 0, 0, 0.1);
}
.rc-tree-node-selected {
  background-color: #ffe6b0;
  border: 1px #ffb951 solid;
  opacity: 0.8;
}
.rc-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px -16px;
}
.rc-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px 0;
}
.rc-tree-icon__docu {
  margin-right: 2px;
  vertical-align: top;
  background-position: -110px -32px;
}
.rc-tree-icon__customize {
  margin-right: 2px;
  vertical-align: top;
}
.rc-tree-indent-unit {
  display: inline-block;
  padding-left: 18px;
}
